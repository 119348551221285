import { History, LinkedAsset, Releases, Sort, Views } from 'constants/filters'
import { BoolType, LicenseType, ProgramType } from 'constants/resources'
import { ResourceContentType } from './resources'

import { DateRange } from 'helpers/dates'
import { GetApiV1ResourcesQueryParams, Resource } from 'types/api'

export type ResourceFileType =
  | 'png'
  | 'psd'
  | 'eps'
  | 'jpg'
  | 'mp4'
  | 'mov'
  | 'r3d'
  | 'zip'

export type ResourceBoolType = BoolType.True | BoolType.False

export type ReviewItemFilter = {
  file_type: ResourceFileType[]
  license?: Resource['license']
  category_ids: string[]
  content_type: ResourceContentType[]
  program_type?: ProgramType
  releases?: Releases
  history?: History
  sort?: Sort
  nonce?: string
  ai_generated: ResourceBoolType[]
  linked_asset?: LinkedAsset
  auto_submitted: ResourceBoolType[]
  magic_metadata: ResourceBoolType[]
  auto_distribute: ResourceBoolType[]
  duplicate: ResourceBoolType[]
  uploaded_at?: DateRange
  reviewed_at?: DateRange
  approved_at?: DateRange
}

export type ItemFilter = {
  [key: string]: unknown
  file_type?: ResourceFileType[]
  content_type?: ResourceContentType | 'all'
  category_ids?: string[]
  license?: GetApiV1ResourcesQueryParams['license'] | 'all' | LicenseType
  program_type?: Resource['program_type'] | 'all'
  sort?: string
  status?: string
  show_only?: string
  nonce?: string
  period?: string
  view?: Views
  category?: string
}

export enum PanelType {
  General = 'general',
  Metadata = 'metadata',
  Review = 'review',
  Contributor = 'contributor',
  Comments = 'comments',
  Other = 'other',
  Actions = 'action',
  Marketplaces = 'marketplaces',
  Thumbnail = 'thumbnail',
  SEOMetadata = 'seo-metadata',
  FileDetails = 'file-details',
  History = 'history',
}

export enum FieldType {
  Status = 'status',
  StatusNotApproved = 'status-not-approved',
  Category = 'category',
  License = 'license',
  Rating = 'rating',
  AIGenerated = 'ai-generated',
  AutoSubmitted = 'auto-submitted',
  MagicMetadata = 'magic-metadata',
  Releases = 'releases',
  ReviewType = 'review-type',
  Program = 'program',
  FileType = 'file-type',
  LinkedAssets = 'linked-assets',
  IDVerification = 'id-verification',
  DuplicateFingerprint = 'duplicate-fingerprint',
  AutoDistribution = 'auto-distribution',
  Marketplaces = 'marketplaces',
  MarketplaceStatus = 'marketplace-status',
  FileHistory = 'file-history',
  Uploaded = 'uploaded',
  Reviewed = 'reviewed',
  Approved = 'approved',
  Updated = 'updated',
  Submitted = 'submitted',
  ReviewerEmail = 'reviewer-email',
  DateApproved = 'date-approved',
  DateRejected = 'date-rejected',
  Title = 'title',
  Keywords = 'keywords',
  ImportGuid = 'import-guid',
  Fingerprint = 'fingerprint',
  AdminComments = 'admin-comments',
  ContributorName = 'contributor-name',
  ContributorEmail = 'contributor-email',
  ContributorScore = 'contributor-score',
  ContributorRating = 'contributor-rating',
  ReviewerNotes = 'reviewer-notes',
  SubmissionQuotas = 'submission-quotas',
  FileName = 'file-name',
  ConfirmedOwner = 'confirmed-owner',
  FileSize = 'file-size',
  Resolution = 'resolution',
  FrameRate = 'frame-rate',
  VideoCodec = 'video-codec',
  Bitrate = 'bitrate',
  SourceFiles = 'source-files',
  StatusChanges = 'status-changes',
  ExportFailure = 'export-failure',
  RetryExportAction = 'retry-export-action',
  ViewAction = 'view-action',
  ViewOnVecteezyAction = 'view-on-vecteezy-action',
  DownloadAction = 'download-action',
  RequestChangesAction = 'request-changes-action',
  ExpectedReviewDate = 'expected-review-date',
  MostDownloadsSort = 'most-downloads-sort',
  LeastDownloadsSort = 'least-downloads-sort',
  MostEarningsSort = 'most-earnings-sort',
  LeastEarningsSort = 'least-earnings-sort',
  MoveBackToAddDataAction = 'move-back-to-add-data-action',
}

export enum FilterPanelType {
  Sort = 'sort',
  Status = 'status',
  Category = 'category',
  ContentType = 'content',
  LicenseType = 'license',
  ProgramType = 'program',
  FileType = 'file-type',
  Release = 'release',
  Rating = 'rating',
  ReviewType = 'review-type',
  IdVerification = 'id-verification',
  FileHistory = 'file-history',
  Uploaded = 'uploaded',
  Approved = 'approved',
  AIGenerated = 'ai-generated',
  Duplicate = 'duplicate',
  LinkedAsset = 'linked-asset',
  AutoSubmitted = 'auto-submitted',
  MagicMetadata = 'magic-metadata',
  AutoDistribution = 'auto-distribution',
  DistributionMarketplaces = 'distribution-marketplaces',
  DistributionStatus = 'distribution-status',
  Reviewed = 'reviewed',
}
