export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum Sort {
  Newest = 'newest',
  Oldest = 'oldest',
  Downloads = 'downloads',
  DownloadsDesc = 'downloads-desc',
  DownloadsAsc = 'downloads-asc',
  Popular = 'popular',
  Views = 'views',
  Earnings = 'earnings',
  EarningsDesc = 'earnings-desc',
  EarningsAsc = 'earnings-asc',
  TitleDesc = 'title-desc',
  TitleAsc = 'title-asc',
  AllTimeEarnings = 'all_time_earnings',
}

export enum SortField {
  AllTimeEarnings = 'all_time_earnings',
  CreatedAt = 'created_at',
}

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum History {
  With = 'with_history',
  Without = 'without_history',
}

export enum Releases {
  With = 'with_releases',
  Without = 'without_releases',
}

export enum DistributionMarketplace {
  All = 'all',
}

export enum Status {
  All = 'all',
  PendingReview = 'submitted',
  AcceptChanges = 'accept_changes',
  Flagged = 'flagged',
  ActionNeeded = 'needs_corrections',
  Approving = 'approving',
  Approved = 'approved',
  Exporting = 'exporting',
  Published = 'active',
  Rejected = 'rejected',
  Started = 'started',
  Inactive = 'inactive',
  Submitting = 'submitting',
}

export enum Views {
  Total = 'total',
  Average = 'average_per_file',
}

export enum LinkedAsset {
  SVG = 'svg',
  None = 'none',
}
